<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} subscriptions</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">Found {{meta.total}} subscriptions that match '{{query}}'</span>
      <div class="d-flex ml-auto">
        <select
          v-model="orderColumn"
          class="form-control custom-select w-auto"
          @change="refresh">
          <option value="">Most recent first</option>
          <option value="status">Order by status</option>
        </select>
        <router-link
          v-if="can(uiPermissions.SUBSCRIPTIONS_CREATE)"
          to="/subscriptions/new"
          data-test="btn-new-subscription"
          class="btn btn-primary ml-2">
          New subscription
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <dimmer :active="listLoading">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr>
                    <th class="w-1"></th>
                    <th>Subscription</th>
                    <th>Next payment</th>
                    <th>Next delivery</th>
                    <th class="text-center">Orders</th>
                    <th>Last order</th>
                    <th>Status</th>
                    <th class="w-1">Last updated</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-center">
                      <span
                        class="avatar"
                        :style="`background-image: url(${identicon(item.customer.user.email + item.product.name)});`"></span>
                    </td>
                    <td>
                      <div>
                        <router-link
                          v-if="can(uiPermissions.SUBSCRIPTIONS_VIEW)"
                          :to="`/subscriptions/${item.id}`">
                          #{{item.id}}
                        </router-link>
                        <span v-else>#{{item.id}}</span>
                        for
                        <router-link
                          v-if="can(uiPermissions.CUSTOMERS_VIEW)"
                          :to="`/users/${item.customer.id}`">
                          {{truncate(displayName(item.customer.user))}}
                        </router-link>
                        <span v-else>{{truncate(displayName(item.customer.user))}}</span>
                      </div>
                      <div class="small text-muted">
                        {{`${item.product.menu.name}
                      box for ${item.product.number_of_people}
                      persons and ${item.product.number_of_recipes} recipes`}}
                      </div>
                    </td>
                    <td>
                      {{moment(item.nextPaymentDate).format('D MMM YYYY')}}
                    </td>
                    <td>
                      {{moment(item.nextDeliveryDate).format('D MMM YYYY')}}
                    </td>
                    <td class="text-center">{{item.orders}}</td>
                    <td>
                      {{moment(item.lastOrderDate).format('D MMM YYYY')}}
                    </td>
                    <td>{{status(item)}}</td>
                    <td>
                      {{moment.utc(item.updatedAt).local().fromNow()}}
                    </td>
                    <td class="text-center">
                      <div v-if="can([uiPermissions.SUBSCRIPTIONS_VIEW])" class="item-action dropdown">
                        <a
                          tabindex="0"
                          data-toggle="dropdown"
                          class="icon"><i class="fe fe-more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link
                            v-if="can(uiPermissions.SUBSCRIPTIONS_VIEW)"
                            :to="`/subscriptions/${item.id}`"
                            class="dropdown-item">
                            <i class="dropdown-icon fe fe-edit"></i> Edit
                          </router-link>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="9">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </dimmer>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {subscriptions} from '@/services';
import SubscriptionStatuses from '@hellochef/shared-js/enums/SubscriptionStatuses';
import submitting from '@hellochef/shared-js/mixins/submitting';

export default {
  components: {
    Pagination,
  },
  mixins: [
    list,
    submitting,
  ],
  data() {
    return {
      orderColumn: '',
    };
  },
  methods: {
    fetchData(page) {
      return subscriptions.getByParameters({

        column: this.orderColumn,
        direction: this.orderColumn === 'status' ? 'asc' : 'desc',
        limit: this.limit,
        page,
        query: this.query,
      });
    },
    status(item) {
      return Object.keys(SubscriptionStatuses).find(key => SubscriptionStatuses[key] === item.status);
    },
  },
};
</script>
